import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "lib-flexible";
import axios from "axios";
Vue.prototype.$axios = axios;
import {
  Button,
  Rate,
  Image as VanImage,
  Lazyload,
  Divider,
  List,
  PullRefresh,
  Toast,
} from "vant";
Vue.use(Button)
  .use(Rate)
  .use(VanImage)
  .use(Lazyload)
  .use(Divider)
  .use(List)
  .use(Toast)
  .use(PullRefresh);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
