import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "game",
    component: () => import("../views/game.vue"),
  },
  {
    path: "/index",
    name: "index", // index
    component: () => import("../views/index.vue"),
  },
];

const router = new VueRouter({
  // mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

export default router;
